const installments = require('./common/installments')
const transactions = require('./common/transactions')
const { pending } = require('./common/pending')
const installmentsListSelector = require('./common/installments-list-selector')

const installmentsSelect = installmentsListSelector(`WHERE ("amount" - coalesce("amount_paid", 0) > 0)`)
const transactionsListSelector = require('./common/transactions-list-selector')

const payableListTransactionSelect = transactionsListSelector(`
  WHERE "txn_type" = 'invoice' AND ( coalesce("amount", 0) - coalesce("amount_paid", 0) ) > 0
`)

const createPayablesListQuery = () => {
  const cte = [
    transactions,
    installments,
    pending,
    `transactions_list AS (
      SELECT tl.* FROM (
        ${payableListTransactionSelect}
        UNION
        SELECT * FROM (${installmentsSelect}) il
      ) tl
      LEFT OUTER JOIN pending p
        ON p.location_id = tl.location_id
       AND p.quickbooks_doc_number = tl.quickbooks_doc_number
      WHERE p.id IS NULL
    ),
    location_transactions AS (
      SELECT * FROM transactions_list 
        WHERE "location_id" = $1
    ),
    overdue_transactions AS (
      SELECT
        'overdue' AS week,
        coalesce (jsonb_agg(t order by t.due_date desc, t.txn_type asc), '[]'::jsonb) AS transactions,
        coalesce (sum(amount - coalesce(amount_paid, 0)), 0) AS totalDue
      FROM location_transactions t WHERE due_date < current_date and (amount - amount_paid) <> 0
    ),
    weekly_transactions AS (
      SELECT
        CASE
          WHEN (extract (day from date_trunc('week', due_date) - date_trunc('week', CURRENT_TIMESTAMP))::integer / 7) < 0 THEN '0'
          ELSE (extract (day from date_trunc('week', due_date) - date_trunc('week', CURRENT_TIMESTAMP))::integer / 7)::text
        END AS week,
        jsonb_agg(t order by t.due_date desc, t.txn_type asc) AS transactions,
        sum(amount - coalesce(amount_paid, 0)) AS totalDue
      FROM location_transactions t WHERE due_date >= current_date and (amount - amount_paid) <> 0
      group by week order by week
    )
    `
  ]
  return `
    WITH ${cte.join(', ')}
    SELECT * FROM overdue_transactions
    UNION ALL
    SELECT * FROM weekly_transactions
  `
}

module.exports = { createPayablesListQuery }
