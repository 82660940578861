const get = require('lodash/get')
const { EntityApi } = require('../common')
const tools = require('./tools')
const PaymentPlansAdapter = require('./payment-plans-adapter')
const PaymentInstallmentAdapter = require('./payment-installment-adapter')
const PaymentPlanOptionsAdapter = require('./payment-plan-options-adapter')

class PaymentPlansApiAdapter {
  constructor (restAdapter, locationApi) {
    this.restAdapter = restAdapter
    this.locationApi = locationApi
  }

  async getUserPaymentPlans (userLocation, returnAll) {
    const uuid = get(await this.locationApi.get(userLocation), 'additionalData.uuid')
    if (!uuid) {
      throw new Error(`location ${userLocation} missing uuid`)
    }

    const limit = 50 // default limit
    const queryParams = {
      location: uuid,
      limit: returnAll ? Number.MAX_SAFE_INTEGER : limit
    }

    try {
      const { results } = await this.restAdapter.get('payment_plan/', queryParams)
      return results
    } catch (e) {
      throw new Error(`Failed to get payment plans for ${userLocation}`)
    }
  }

  async createInstalments (payload) {
    // this creates the payment plan instalments, when we send a PUT request
    payload.is_active = true
    try {
      return this.restAdapter.update(`payment_plan/create_payment_plan/`, payload)
    } catch (e) {
      throw new Error(`Failed to create installments for payment plan with id ${payload.id}`)
    }
  }
}

class PaymentPlansApi extends EntityApi {
  constructor (state, restAdapter, locationApi, pgConnection, agaveAdapter) {
    const paymentPlansAdapter = new PaymentPlansApiAdapter(restAdapter, locationApi)
    super(paymentPlansAdapter)
    this.user = state.user
    this.tools = tools
    this.agaveAdapter = agaveAdapter
    if (pgConnection) {
      const { user = {} } = state
      const username = user.name
      const paymentPlanOptionsAdapter = new PaymentPlanOptionsAdapter(pgConnection, username)
      this.paymentPlanAdapter = new PaymentPlansAdapter(pgConnection, username)
      this.installmentAdapter = new PaymentInstallmentAdapter(pgConnection, username)
      this.options = {
        generatePaymentPlanOptions: paymentPlanOptionsAdapter.generatePaymentPlanOptions.bind(paymentPlanOptionsAdapter),
        pg: {
          getPaymentPlanOption: paymentPlanOptionsAdapter.getPaymentPlanOption.bind(paymentPlanOptionsAdapter),
          getPaymentPlanOptionViaTxnId: paymentPlanOptionsAdapter.getPaymentPlanOptionViaTxnId.bind(paymentPlanOptionsAdapter),
          create: paymentPlanOptionsAdapter.create.bind(paymentPlanOptionsAdapter)
        }
      }
    }
  }

  async getUserPaymentPlans (userLocation = this.user.location.id, returnAll = false) {
    const plans = await this.adapter.getUserPaymentPlans(userLocation, returnAll)
    return plans.map(plan => tools.addAmountPaidToInstalments(plan))
  }

  getPaymentPlansBulk (locationIds) {
    return this.agaveAdapter.post('payment_plans/bulk', {locationIds})
  }

  createPaymentPlan (paymentPlan, qboTxnId) {
    return this.paymentPlanAdapter.createPaymentPlan(paymentPlan, qboTxnId)
  }

  updatePaymentPlan (paymentPlan) {
    return this.paymentPlanAdapter.update(paymentPlan)
  }

  async activatePaymentPlan (paymentPlanId) {
    const { results } = await this.installmentAdapter.getList({
      filter: {
        payment_plan_id: paymentPlanId
      }
    })
    const instalments = results.length ? results : (await this.installmentAdapter.createInstalments(paymentPlanId))
    const plan = await this.updatePaymentPlan({ id: paymentPlanId, is_active: true })
    return {
      installments: instalments,
      paymentPlan: plan
    }
  }

  /**
   *
   * @param {String} paymentPlanId
   * @param {number} accountCurrentBalance
   *
   * @returns {Promise<void>}
   */
  async bookPaymentPlanBalance (paymentPlanId, accountCurrentBalance) {
    await this.updatePaymentPlan({
      id: paymentPlanId,
      quickbooks_account_balance: accountCurrentBalance,
      is_paid: accountCurrentBalance <= 0
    })

    const [updatedPlan] = await this.installmentAdapter.getUnpaidInstalments(paymentPlanId)
    if (!updatedPlan) {
      return
    }
    const updateInstalments = updatedPlan.installments
      .map(instalment => {
        return {
          id: instalment.id,
          is_paid: instalment.is_paid
        }
      })
    await this.installmentAdapter.update(updateInstalments)
  }
}

module.exports = PaymentPlansApi
