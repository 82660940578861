import React, { Component } from 'react'
import PropTypes from 'prop-types'

import withConfig from '../../../van-shared/hoc/withConfig'
import AppContainer from '../app/AppContainer'
import SyncViewPage from '../../../van-shared/components/SyncViewPage'

class Root extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    isSyncFinished: PropTypes.bool,
    percentComplete: PropTypes.number
  }

  render () {
    const {
      routerParams,
      rootHistory,
      config,
      isSyncFinished,
      percentComplete
    } = this.props

    if (!isSyncFinished) {
      return <SyncViewPage progress={percentComplete} />
    }

    return (
      <AppContainer
        rootHistory={rootHistory}
        routerParams={routerParams}
        config={config}
      />
    )
  }
}

export default withConfig(Root)
