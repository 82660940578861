const { batchIdToProductId } = require('./product-batch-util')

exports.calculateShipmentVolume = calculateShipmentVolume
async function calculateShipmentVolume (shipment, mainApi) {
  let volume = 0

  for (const batchId of Object.keys(shipment.counts)) {
    const productId = batchIdToProductId(batchId)
    const { quantity } = shipment.counts[batchId]
    const product = await mainApi.product.get(productId)
    const unitVolume = product.unitVolume
    volume += quantity * unitVolume
  }
  return volume
}
