import { createSlice } from '@reduxjs/toolkit'
import { userIsAuthorised } from '../../../../van-shared/utils/auth'

// This is the initial state for reports reducer
// TODO: refactor the component(s) in the report app making use of
// user and config from here. We can introduce `withUser` and `withConfig` HOCs there
const initialState = {
  user: {},
  config: {},
  hasAlerts: false,
  deviceId: ''
}

export const getInitialState = (user, deviceId, config) => {
  return {
    root: {
      ...initialState,
      deviceId,
      user,
      config,
      hasAlerts: userIsAuthorised(user, 'feature:qc-alerts')
    }
  }
}

export const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    updateRoot: (state, action) => {
      const { deviceId, user, config, hasAlerts } = action.payload.root
      state.deviceId = deviceId
      state.user = user
      state.config = config
      state.hasAlerts = hasAlerts
    }
  }
})

export const { updateRoot } = rootSlice.actions

export const selectRoot = (state) => state.root

export default rootSlice.reducer
