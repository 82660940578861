const PGAdapter = require('./../common/pg-adapter')
const { createLateFeesQuery } = require('./queries/late-fees-query')
const { getPositionalArgs } = require('../tools/sql-tools')
const LATE_FEE_LINES_TABLE_NAME = 'avocado.late_fee'
const LATE_FEE_LINES_COLUMNS = [
  'event_id',
  'seq',
  'id',
  'location_id',
  'created_at',
  'sku',
  'item_name',
  'description',
  'amount',
  'fee_date',
  'percentage',
  'overdue_amount'
]

class LateFeeInvoicingAdapter extends PGAdapter {
  constructor (pgConnection, username, logger) {
    super(
      pgConnection,
      LATE_FEE_LINES_TABLE_NAME,
      username,
      LATE_FEE_LINES_COLUMNS,
      'id',
      null,
      logger
    )
  }

  /**
  * @returns {Promise<{lateFeeInvoiceLines: Object[], lateFeeInstalmentLines: Object[]>}
  */
  async processLateFees ({locationIds = [], lateFeeThreshold}) {
    if (!locationIds.length) {
      return { lateFeeInvoiceLines: [], lateFeeInstalmentLines: [] }
    }
    const dollarSigns = getPositionalArgs(locationIds)
    const lateFeeQuery = createLateFeesQuery({writeMode: true, lateFeeThreshold, locationIdArgs: dollarSigns})
    const { rows } = await this.pgConnection.query(lateFeeQuery, locationIds)
    return rows[0]
  }

  /**
  * @returns {Promise<[]>}
  */
  async getPendingLateFees ({locationIds = [], lateFeeThreshold}) {
    if (!locationIds.length) {
      return []
    }
    const dollarSigns = getPositionalArgs(locationIds)
    const lateFeeQuery = createLateFeesQuery({writeMode: false, lateFeeThreshold, locationIdArgs: dollarSigns})
    const { rows } = await this.pgConnection.query(lateFeeQuery, locationIds)
    return rows
  }
}

module.exports = LateFeeInvoicingAdapter
