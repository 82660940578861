import React, { Component } from 'react'
import { connect } from 'react-redux'
import flowRight from 'lodash/flowRight'
import PropTypes from 'prop-types'

import getOrCreateDeviceId from '@fielded/fs-api/lib/shipment/tools/find-or-generate-device-id'

import { getLocalPouchDB } from '../../../sync'
import withConfig from '../../../van-shared/hoc/withConfig'
import { withUser } from '../../../common/AuthenticationProvider'
import { withApi } from '../../../common/ApiProvider'
import { getInitialState, updateRoot } from '../../../root/reducers/reports/root'

import AppContainer from '../app/AppContainer'
import SyncViewPage from '../../../van-shared/components/SyncViewPage'

class Root extends Component {
  state = {
    initialized: false
  }

  static propTypes = {
    config: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    isSyncFinished: PropTypes.bool,
    percentComplete: PropTypes.number
  }

  getChildContext () {
    return {
      rootHistory: this.props.rootHistory
    }
  }

  async componentDidMount () {
    const { user, config, updateRoot } = this.props
    const { deviceId } = await getOrCreateDeviceId({ PouchDB: getLocalPouchDB(user) })
    const initialState = getInitialState(user, deviceId, config)

    updateRoot(initialState)
    this.setState({ initialized: true })
  }

  render () {
    const { initialized } = this.state

    if (!initialized) {
      return null
    }

    const {
      config,
      routerParams,
      rootHistory,
      api,
      showCountSyncBanner,
      showShipmentSyncBanner,
      isSyncFinished,
      percentComplete
    } = this.props

    if (!isSyncFinished) {
      return <SyncViewPage progress={percentComplete} />
    }

    return (
      <AppContainer
        config={config}
        api={api}
        routerParams={routerParams}
        rootHistory={rootHistory}
        showCountSyncBanner={showCountSyncBanner}
        showShipmentSyncBanner={showShipmentSyncBanner}
      />
    )
  }
}

Root.childContextTypes = {
  rootHistory: PropTypes.object
}

// TODO: Refactor this to a functional component and use hooks
const mapDispatchToProps = {
  updateRoot
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig,
  connect(null, mapDispatchToProps)
)

export default withHOCs(Root)
