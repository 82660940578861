/*
 * This is a specialized function including all the logic to create drafts and view reports
 * in field-supply. It's not what you want for just retreiving reports, then you should use
 * `api.report.findForLocation` or `api.report.find`
 */
const { isHeldLocation } = require('../..//shipment/tools/is-held-location')
const getDefaultDisabled = require('../tools/get-default-disabled')
const deepGet = require('lodash/get')
const deepSet = require('lodash/set')
const keyBy = require('lodash/keyBy')
const { parse } = require('../../tools/smart-id')

// internal api methods:
const getLedgerBalanceAsReport = require('../../stock-get-ledger-balance-as-report')

const { listForLocations: productListForLocations } = require('../../product/api/read/list-for-locations')
const { getByIds: productGetByIds } = require('../../product/api/read/get-by-ids')
const saveReportDraft = require('./save-draft')
const findReportDraft = require('./find-draft')
const removeReportDraft = require('./remove-draft')
const createDraft = require('./create-draft')
const findForLocation = require('./find-for-location')
const { getPeriod, getPreviousPeriod } = require('./get-periods')
const { get: getService } = require('../../service/api/read/get')
const { get: getLocation } = require('../../location')
const { MEMBERSHIPS } = require('../../location/tools/constants')
const { DIRECT_ORDER_TYPES } = require('../../allocation/config')

// We can submit reports after it ends now since we added adjustments:
const getLedgerDate = period => {
  const currentDate = new Date().toJSON()
  const endOfPeriod = period.entryEndDate.toJSON()

  if (currentDate > endOfPeriod) {
    return currentDate
  }

  return endOfPeriod
}

function removeUnavailableProductsWithNoStock (report, products, locationId) {
  if (isHeldLocation(locationId)) {
    return
  }

  const productsWithSlStock = Object.keys(report.stock)
    .filter(productId => {
      const partnerBalance = deepGet(report, `stock.${productId}.fields.field:opening-partner-balance.amount`, 0)
      const totalBalance = deepGet(report, `stock.${productId}.fields.field:standard-opening-balance.amount`, 0)
      return totalBalance - partnerBalance > 0
    })

  const productsToHide = Object.keys(products)
    .filter(p => (!products[p].additionalData.available || (products[p].additionalData.visiblity_1 && products[p].additionalData.visiblity_1.toLowerCase() === 'discontinued')))
    .filter(p => !productsWithSlStock.includes(p))

  for (var productId of productsToHide) {
    report.stock[productId].removed = true
  }
}

const createRealtimeDraft = async (state, {
  locationId,
  service,
  period,
  excludeProducts
}) => {
  // VAN and Shelflife have realtime Shipments and thus require the ledger balance.

  const ledgerDate = getLedgerDate(period)
  const location = await getLocation(state, locationId, ledgerDate)

  const isExternalPackPoint = location.level === 'pack-point' && location.additionalData.classification === 'External Distribution Center'
  const excludeSubscriptions =
   location.membership === MEMBERSHIPS.CLASSIC_PAYG_ONLY
     ? []
     : [DIRECT_ORDER_TYPES.PAY_ON_DELIVERY]

  const report = await getLedgerBalanceAsReport(state, {
    date: ledgerDate,
    service,
    location: {id: locationId},
    excludeProducts,
    // Exclude on demand products for SL report entry except for external packpoints
    excludeSubscriptions: isExternalPackPoint ? undefined : excludeSubscriptions
  })

  // Shelf Life counts should only have opening balance pre-filled.
  // The physical counts are done from scratch for each product.
  Object.keys(report.stock).forEach(productId => {
    const product = report.stock[productId]
    product.fields['field:standard-opening-balance'] = { amount: product.fields['field:standard-physical-count'].amount }
    product.fields['field:standard-physical-count'].amount = undefined

    const partnerBalanceAmount = deepGet(product, 'fields.field:partner-balance.amount', 0)
    product.fields['field:opening-partner-balance'] = { amount: partnerBalanceAmount }
  })

  return report
}

const updateOpeningBalances = async (state, {existingDraft, locationId, period, service, excludeProducts}) => {
  if (existingDraft.submittedAt) {
    // We do not want to update opening balances of drafts that are based on submitted stock counts
    return existingDraft
  }

  const ledgerDate = getLedgerDate(period)
  const location = await getLocation(state, locationId, ledgerDate)

  const isExternalPackPoint = location.level === 'pack-point' && location.additionalData.classification === 'External Distribution Center'
  const excludeSubscriptions =
  location.membership === MEMBERSHIPS.CLASSIC_PAYG_ONLY
    ? []
    : [DIRECT_ORDER_TYPES.PAY_ON_DELIVERY]

  const ledger = await getLedgerBalanceAsReport(state, {
    date: ledgerDate,
    service,
    location: {id: locationId},
    excludeProducts,
    // Exclude on demand products for SL report entryj
    excludeSubscriptions: isExternalPackPoint ? undefined : excludeSubscriptions
  })

  // Get product SL subscriptions for location
  const { products: subscriptions } = await productListForLocations(state, [locationId], {exclude: 'configuration', idsOnly: true})

  // Shelf Life counts should only have opening balance pre-filled.
  // The physical counts are done from scratch for each product.
  Object.keys(ledger.stock).forEach(productId => {
    // Might be stuff on the ledger not in the draft
    existingDraft.stock[productId] = existingDraft.stock[productId] || { fields: {} }

    const product = existingDraft.stock[productId]
    const ledgerAmount = deepGet(ledger, `stock.${productId}.fields.field:standard-physical-count.amount`)

    product.fields['field:standard-opening-balance'] = { amount: ledgerAmount }

    const partnerBalanceAmount = deepGet(ledger, `stock.${productId}.fields.field:partner-balance.amount`, 0)

    product.fields['field:opening-partner-balance'] = { amount: partnerBalanceAmount }

    // If the product is not in subscriptions and has no SL balance, delete it from the draft
    if (!Object.keys(subscriptions).includes(productId) && ledger.stock[productId].availableTotal === partnerBalanceAmount) {
      product.removed = true
    }
  })

  return saveReportDraft(state, {
    locationId,
    service,
    period,
    stock: existingDraft.stock,
    expiry: existingDraft.expiry,
    documents: existingDraft.documents,
    partialCount: existingDraft.partialCount
  })
}

const createNonRealtimeDraft = async (state, {
  locationId,
  service,
  period,
  excludeProducts,
  autoPopulateReceived
}) => {
  let report
  let shipmentStock = {}

  // Non realtime reports, should be PSM
  // But we need to get quantity delivered to their locations
  const existingReports = await findForLocation(state, {locationId, serviceId: service.id, period, entityOptions: { addProducts: true, excludeProducts }})
  if (existingReports.length > 0) {
    report = existingReports[0]
  } else {
    report = await createDraft(state, {locationId, service, period, entityOptions: { addProducts: true, excludeProducts }})
    const ledgerDate = getLedgerDate(period)
    const ledger = await getLedgerBalanceAsReport(state, {
      date: ledgerDate,
      service,
      location: {id: locationId},
      excludeProducts: '',
      returnShipmentStock: true
    })

    shipmentStock = Object.keys(ledger.shipmentStock).reduce((acc, batchId) => {
      const { product } = parse(batchId)
      const productId = `product:${product}`

      if (acc[productId]) {
        acc[productId] += deepGet(ledger.shipmentStock[batchId], 'quantity')
        return acc
      }

      acc[productId] = deepGet(ledger.shipmentStock[batchId], 'quantity')
      return acc
    }, {})
  }

  // for services not using realtime shipments the opening balance relies on the
  // previous stock report for opening balance not ledger balance
  const previousPeriod = await getPreviousPeriod(state, {program: service.program, period})
  const previousReports = await findForLocation(state, {locationId, serviceId: service.id, period: previousPeriod})

  if (previousReports.length > 0) {
    const prevReport = previousReports[0]
    Object.keys(report.stock).forEach(productId => {
      const product = report.stock[productId]
      product.fields = product.fields || {'field:standard-physical-count': {amount: undefined}}
      const previousCount = deepGet(prevReport, `stock.${productId}.fields.field:standard-physical-count.amount`)
      const openingBalance = deepGet(product.fields, 'field:standard-opening-balance.amount')

      // Set quantity received
      if (autoPopulateReceived) {
        attachStandardReceived(shipmentStock, productId, product)
      }

      if (typeof openingBalance === 'undefined') {
        product.fields['field:standard-opening-balance'] = { amount: previousCount }
        deepSet(product, 'fields.field:standard-opening-balance.amount', previousCount)
      }

      if (typeof previousCount === 'undefined') {
        deepSet(product, 'fields.field:standard-opening-balance.disabled', false)
      } else {
        deepSet(product, 'fields.field:standard-opening-balance.disabled', true)
      }

      // If the product was disabled in the previous report, make it disabled here as well
      const previouslyDisabled = deepGet(prevReport, `stock.${productId}.disabled`)
      if (typeof previouslyDisabled !== 'undefined') {
        product.disabled = previouslyDisabled
      } else if (typeof previousCount === 'undefined') {
        // If we haven't counted this item before,
        // use the program default
        product.disabled = getDefaultDisabled(service.id)
      } else {
        // If we counted the item in the previous report
        // assume it should be counted again
        product.disabled = false
      }
    })
  } else {
    Object.keys(report.stock).forEach(productId => {
      const product = report.stock[productId]
      const disabled = product && typeof product.disabled !== 'undefined'
        ? product.disabled
        : getDefaultDisabled(service.id)
      product.disabled = disabled
      product.hasPreviousBalance = false
      deepSet(product, 'fields.field:standard-opening-balance.disabled', false)

      // Set quantity received
      if (autoPopulateReceived) {
        attachStandardReceived(shipmentStock, productId, product)
      }
    })
  }

  return report
}

function attachStandardReceived (shipmentStock, productId, product) {
  const shipmentValue = shipmentStock[productId]
  let standardReceived

  // If there is a shipment value use that
  if (shipmentValue) {
    standardReceived = shipmentValue
    deepSet(product, 'fields.field:standard-received.disabled', true)
  } else {
    standardReceived = deepGet(product.fields, 'field:standard-received.amount', 0)
    deepSet(product, 'fields.field:standard-received.disabled', false)
  }

  deepSet(product, 'fields.field:standard-received.amount', standardReceived)
}

async function findReport (state, options) {
  const {
    locationId,
    serviceId,
    date,
    realtime,
    excludeProducts,
    hideUnavailableProducts,
    autoPopulateReceived,
    partialCount,
    dryRun = false
  } = options

  const service = await getService(state, serviceId)
  const period = await getPeriod(state, {program: service.program, date})

  // Find an existing draft
  const existingDraft = await findReportDraft(state, {locationId, service, period})
  if (existingDraft) {
    if (existingDraft.isPristine) {
      // If the draft has no edits we remove it here and continue to
      // create a new one with potentially updated prepopulated fields.
      await removeReportDraft(state, {reportId: existingDraft._id})
    } else {
      if (realtime) {
        return updateOpeningBalances(state, {existingDraft, locationId, service, period, excludeProducts})
      }

      return existingDraft
    }
  }

  // Create a draft
  let report

  if (realtime) {
    report = await createRealtimeDraft(state, {locationId, service, period, excludeProducts})
  } else {
    report = await createNonRealtimeDraft(state, {locationId, service, period, excludeProducts, autoPopulateReceived})
  }

  if (hideUnavailableProducts) {
    const products = await productGetByIds(state, Object.keys(report.stock), { date: new Date(date).toJSON() })
    removeUnavailableProductsWithNoStock(report, keyBy(products, '_id'), locationId)
  }

  return saveReportDraft(state, {
    locationId,
    service,
    period,
    stock: report.stock,
    expiry: report.expiry,
    documents: report.documents,
    isPristine: true,
    partialCount,
    // If the base of the draft is a submitted stock count of the same week
    // we add the `submittedAt` date to the draft. If the base is a ledger, we
    // don't do that, because then the `submittedAt` date would be from the stock
    // count the ledger is based on.
    // Only reports created from ledgers will have the `hasStockCount` property.
    submittedAt: report.hasOwnProperty('hasStockCount') ? null : report.submittedAt,
    dryRun
  })
}

module.exports = findReport
