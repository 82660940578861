import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { BackButton, Button, DeemphasizedLink, Form, KeyValueTable, Loading, Page, Text, StatusLabel } from '@fielded/shared-ui'
import { toast } from '@fielded/shared-ui/src/components/Page'
import { InfoCircle } from '@fielded/shared-ui/src/icons'

// import { userIsAuthorised } from '../../../van-shared/utils/auth'
import { reset as resetOverview } from '../../../root/reducers/reports/overview'
import { withUser } from '../../../common/AuthenticationProvider'
import { withApi } from '../../../common/ApiProvider'
import withConfig from '../../../van-shared/hoc/withConfig'
import { pluralize } from '../../../van-shared/utils'

const DEFAULT_WITHOUT_CREDIT = false

// TODO: Only show buttons for PB location
class TransferContainer extends Component {
  state = {
    loading: true,
    confirm: false,
    source: 'partner',
    quantity: null,
    balance: null,
    withoutCredit: DEFAULT_WITHOUT_CREDIT
  }

  async componentDidMount () {
    const { api } = this.props
    const { productId, serviceId, locationId } = this.props.match.params
    const service = await api.service.get(serviceId)
    const product = await api.product.get(productId)
    const location = await api.location.get(locationId)
    const ledger = await api.stock.getLedgerBalance({
      location: { id: locationId },
      service
    })

    const total = get(ledger, `${productId}.availableTotal`)
    const partner = get(ledger, `${productId}.field:partner-balance`)
    const shelflife = total - partner

    this.setState({
      location,
      product,
      service,
      loading: false,
      balance: {
        total, partner, shelflife
      }
    })
  }

  validateQuantity = (value) => {
    const { balance, source } = this.state
    if (value <= 0) {
      return 'Please enter a positive number of items to transfer'
    }

    if (value > balance[source]) {
      return `You can only transfer ${balance[source]} ${pluralize('item', balance[source])}`
    }
  }

  setSource = (source) => {
    this.setState({ source })
  }

  setQuantity = (quantity) => {
    this.setState({ quantity })
  }

  toConfirm = () => {
    this.setState({ confirm: true })
  }

  toEntry = () => {
    this.setState({ confirm: false })
  }

  handleWithoutCreditCheck = (isChecked) => {
    this.setState({ withoutCredit: isChecked })
  }

  confirmTransfer = async () => {
    this.setState({ loading: true })

    try {
      const { productId, locationId } = this.props.match.params
      const { resetOverview } = this.props
      let { service, quantity, source, withoutCredit } = this.state

      if (source === 'shelflife') {
        quantity = -quantity
      }

      console.log(
        'service', service,
        'quantity', quantity,
        'source', source,
        'withoutCredit', withoutCredit
      )

      await this.props.api.report.transfer({
        locationId,
        service,
        productId,
        quantity,
        withoutCredit
      })

      toast({
        title: 'Balance transfer created',
        type: 'success',
        autoDismiss: 5000
      })

      // Make sure transfer shows up
      // in report listing in overview:
      resetOverview()

      // I used history.goBack here instead of push/replace
      // so the chain of "back" buttons work alright, see
      // https://github.com/fielded/field-supply/pull/636#pullrequestreview-485848729
      this.props.history.goBack()
    } catch (e) {
      toast({
        title: 'Transfer error',
        children: e.message,
        type: 'warning',
        autoDismiss: false
      })

      this.setState({ loading: false })
    }
  }

  render () {
    const {
      history,
      config
    } = this.props
    const {
      loading,
      location,
      product,
      balance,
      source,
      quantity,
      confirm
    } = this.state

    const appName = config.name

    if (loading) {
      return <Loading />
    }

    const tableData = [
      { 'text': 'Quantity on shelf', value: balance.total },
      { 'text': `${location.name} stock`, value: balance.partner },
      { 'text': `${appName} stock`, value: balance.shelflife }
    ]

    // We want to disable this feature for the time being
    const canTransferWithoutCredit = false
    // const canTransferWithoutCredit = userIsAuthorised(user, 'feature:userRole:planner')
    const withoutCreditNoteText = 'Check this off only if invoice is not sent, or you are making a fix for the same week and don\'t need a separate credit'

    return (
      <Page>
        <Page.Header
          backButton={<BackButton history={history} />}
          title={'Transfer stock ownership'}
          className='transfer-header'
        />
        {!confirm && (
          <div className='transfer'>
            <div className='transfer__header'>
              <div className='transfer__info'>
                <Text size='xlarge' bold>{product.name}</Text>
                <Text size='large'>{location.name}</Text>
              </div>
              <div className='transfer__summary'>
                <KeyValueTable
                  entries={tableData}
                  semiHeader
                  alternated
                />
              </div>
            </div>
            <div className='transfer__content'>
              <Form onSubmit={this.toConfirm}>
                <Form.Row>
                  <Form.Field
                    fieldName='source'
                    labelText='Transfer stock from'
                  >
                    <Form.Field.Options
                      defaultValue={source}
                      options={[
                        { value: 'partner', label: `Pharmacy to ${appName}` },
                        { value: 'shelflife', label: `${appName} to Pharmacy` }
                      ]}
                      onValueChange={this.setSource}
                    />
                  </Form.Field>
                </Form.Row>
                <Form.Row>
                  <Form.Field
                    fieldName='quantity'
                    labelText='Quantity to transfer'
                  >
                    <Form.Field.Quantity
                      value={quantity}
                      onValueChange={this.setQuantity}
                      validate={this.validateQuantity}
                      className='ledger-transfer__input'
                    />
                    <Form.Field.ValidationErrors />
                  </Form.Field>
                </Form.Row>
                {canTransferWithoutCredit && (
                  <Fragment>
                    <Form.Row>
                      <Form.Field
                        fieldName='withoutCredit'
                      >
                        <Form.Field.Checkbox
                          label='Make this Transfer without credit'
                          defaultChecked={DEFAULT_WITHOUT_CREDIT}
                          disabled={false}
                          onValueChange={this.handleWithoutCreditCheck}
                        />
                      </Form.Field>
                    </Form.Row>
                    <Form.Row>
                      <StatusLabel status='attention' label={withoutCreditNoteText} icon={<InfoCircle />} />
                    </Form.Row>
                  </Fragment>
                )}
                <Form.Actions layout='right'>
                  <Form.Button
                    type='submit'
                    disabled={!quantity}
                  >
                  Submit
                  </Form.Button>
                  <Form.Button
                    onClick={() => history.goBack()}
                    alignOpposite
                    variant='deemphasized'>
                    Cancel and go back
                  </Form.Button>
                </Form.Actions>
              </Form>
            </div>
          </div>)}
        {confirm &&
          <div className='transfer transfer--confirmation'>
            <Page.Panel.Section withMargin>
              <Text.Heading className='transfer__heading'>Confirm transfer of stock ownership</Text.Heading>
              <Text tag='p' className='transfer__subtitle'>
                You are transferring <Text tag='span' weight='bold'>{quantity || 0} {pluralize('unit', quantity || 0)} of {product.name}</Text> from {source === 'partner' ? location.name : 'Shelflife'} to {source === 'partner' ? 'Shelflife' : location.name}.
              </Text>
            </Page.Panel.Section>
            <Page.Panel.Section withMargin>
              <Button
                colorVariant='brand'
                size='large'
                spread={false}
                fill='full'
                onClick={this.confirmTransfer}>
                Confirm
              </Button>
            </Page.Panel.Section>
            <Page.Panel.Section withMargin>
              <DeemphasizedLink
                onClick={this.toEntry}
              >
                Go back
              </DeemphasizedLink>
            </Page.Panel.Section>
          </div>}
      </Page>
    )
  }
}

/*
          */
export default withConfig(withUser(withApi(connect(null, { resetOverview })(TransferContainer))))
