import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { userIsAuthorised } from '../../../../van-shared/utils/auth'
import { hasFeature } from '../../../../van-shared/utils/features'
import { withUser } from '../../../../common/AuthenticationProvider'

import { addAlertComment, setAlertStatus } from '../../../../root/reducers/reports/report-entry'
import AlertReportField from './AlertReportField'

class AlertReportFieldContainer extends Component {
  static propTypes = {
    alert: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }

  constructor (props) {
    super(props)
    this.state = {
      isAlertExpanded: false
    }
  }

  toggleCloseAlert = (closed) => {
    const {
      setAlertStatus,
      alert,
      deviceId
    } = this.props
    setAlertStatus(alert.id, alert.status === 'closed' ? 'open' : 'closed', deviceId)
  }

  toggleAlertExpandedState = (expanded) => {
    this.setState({
      ...this.state,
      isAlertExpanded: expanded
    })
  }

  saveComment = (comment) => {
    const {
      addAlertComment,
      alert,
      deviceId
    } = this.props
    addAlertComment(alert.id, comment, deviceId)
  }

  render () {
    const {
      alert,
      addAlertComment,
      setAlertStatus,
      config,
      user,
      alertIndicatorsConfig,
      ...fieldProps
    } = this.props
    const { isAlertExpanded } = this.state
    const indicatorInfo = alertIndicatorsConfig[alert.target.indicator]
    const message = indicatorInfo ? indicatorInfo.message : 'no message'

    const showAlert = hasFeature(config.features, 'qcAlerts') && userIsAuthorised(user, 'feature:qc-alerts')

    return (
      <AlertReportField
        isAlertExpanded={isAlertExpanded}
        isAlertClosed={alert.status === 'closed'}
        message={message}
        comments={alert.comments}
        onToggleClosed={this.toggleCloseAlert}
        onSave={this.saveComment}
        onToggleExpanded={this.toggleAlertExpandedState}
        fieldProps={{...fieldProps}}
        showAlert={showAlert}
      />
    )
  }
}

AlertReportFieldContainer.propTypes = {
  alertIndicatorsConfig: PropTypes.object
}

const mapStateToProps = ({ reportEntry, root: { config, deviceId } }) => {
  return {
    alertIndicatorsConfig: reportEntry.alertIndicatorsConfig,
    config,
    deviceId
  }
}

const mapDispatchToProps = {
  addAlertComment,
  setAlertStatus
}

export default withUser(connect(mapStateToProps, mapDispatchToProps)(AlertReportFieldContainer))
